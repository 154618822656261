import { Link } from "gatsby"
import AppHeader from "../V2/AppHeader"
import React from "react"
import HomePageBackgroundContainer from "../V2/HomePageBackgroundContainer"
import { TextTypography } from "../SharedResources/Typography/TextTypography/TextTypography"
import { FontStyle } from "../SharedResources/Typography/typography.service"
import AppFooter from "../AppFooter/AppFooter"
import CurveContainer from "../CurveContainer/CurveContainer"
import AcademicFormWithCard from "../SignUp/AcademicsSignUp/AcademicFormWithCard"
import DecisionMakerFormWithCard from "../SignUp/DecisionMakerSignUp/DecisionMakerFormWithCard"

const DomeApplyNowPage: React.FC = () => {
  return (
    <main className="w-full">
      <AppHeader />
      <HomePageBackgroundContainer
        mainHeading={"Get Involved without \n Getting Tracked"}
        className="pb-32"
        onButtonClick={() =>
          document
            .getElementById("plans")
            ?.scrollIntoView({ behavior: "smooth" })
        }
      />
      <div className="relative -mt-24">
        <CurveContainer color="light">
          <div className="flex flex-col justify-center">
            <TextTypography
              fontStyle={FontStyle.BOLD}
              className="text-center text-5xl"
            >
              Academics
            </TextTypography>
            <AcademicFormWithCard />
            <TextTypography
              fontStyle={FontStyle.BOLD}
              className="text-center text-5xl mt-28"
            >
              Decision Makers
            </TextTypography>
            <DecisionMakerFormWithCard />
          </div>
        </CurveContainer>
      </div>
      <article className="relative flex justify-center text-xl mt-20 md:px-4">
        <div className="flex flex-col space-y-6 max-w-4xl 2xl-mobile:px-8">
          <TextTypography>
            People involved in debating and deciding public policy are on the
            inside and they need access to multiple sources of information from
            the outside, usually in a hurry. Not everyone on the outside can be
            trusted.
          </TextTypography>

          <TextTypography>
            Dome is the only platform designed to put the insiders in control
            and to enable each of them to consume content without detection and
            to ask questions anonymously. For academics and advocates, Dome is
            the only platform which delivers their information and policy
            recommendations to the insiders, but we never reveal to them the
            identities of those who viewed their content.
          </TextTypography>
          <TextTypography>
            At any time, this would be a cool concept. These days, when risks to
            personal safety keep rising, Dome is a necessity.
          </TextTypography>
        </div>
      </article>
      <article className="flex items-center flex-col justify-center mt-16 px-8">
        <TextTypography className="text-lg md:text-3xl text-center max-w-xl md:max-w-5xl">
          Dome is the only way public policy insiders can acquire information
          from the outside without revealing their activities or identities
        </TextTypography>
      </article>
      <article className="flex items-center flex-col mt-16 px-8">
        <TextTypography className="text-lg md:text-3xl text-center">
          With jobs this important, it’s good to be in the know
        </TextTypography>
        <TextTypography
          className="text-lg md:text-3xl text-center"
          fontStyle={FontStyle.BOLDITALIC}
        >
          It’s vital to be in control
        </TextTypography>
      </article>

      <article className="flex items-center flex-col mt-16 px-8">
        <TextTypography className="text-base text-center">
          Each user is asked to pledge to abide by the Dome
          <Link to="/pledge-of-civility">
            <span className="text-primary"> Pledge of Civility,</span>
          </Link>
        </TextTypography>
        <TextTypography className="text-base text-center mb-20">
          making Dome free of uncivil discourse.
        </TextTypography>
      </article>
      <AppFooter />
    </main>
  )
}

export default DomeApplyNowPage
