import * as React from "react"
import FaviconHeader from "../components/FaviconHeader/FaviconHeader"
import DomeApplyNowPage from "../components/DomeApplyNowPage/DomeApplyNowPage"

const ApplyNow = () => {
  return (
    <main>
      <FaviconHeader />
      <DomeApplyNowPage />
    </main>
  )
}

export default ApplyNow
